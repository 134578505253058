import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperService } from "../../storage-helper";
import { AuthenticationService } from "../_service/auth.service";
// import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  model: any = {};
  returnUrl = "app/home/scan/0";
  suberror = [];
  open = true;
  constructor(
    private _authService: AuthenticationService,
    private _route: ActivatedRoute,
    private _helper: HelperService,
    private _router: Router,
    
    // private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {

    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
    this._router.navigate([this.returnUrl]);
    this.checkuser();
  }

  signin() {
    this.suberror = [];// this.ngxService.start();
    this._authService.login(this.model.email, this.model.password).subscribe(
      (data) => {
        if (data["token"]) {
          this._helper.setInlocal("currentUserCS", data["token"]);
          this._helper.setInlocal("isLoggedIn", true);


          this._authService.me().subscribe(
            (res: any) => {

              this._authService.nextMessage(res);
              this._authService.Udetails = res;
              // this.ngxService.stop();

              // if (res.profile == null || res.profile.role == 'admin') {
                this._router.navigate(["app/home/scan/0"]);
              // } else {
                // this._router.navigate(['/pages/layout/detail/' + res.id]);
                // , { queryParams: { client : res.id }})
              // }

              // this._authService.enableTour = false;
            },
            (err) => {
              this.suberror = [];
              this._helper.removeFromLocal("currentUserCS");
              this._helper.removeFromLocal("isLoggedIn");

              // this.ngxService.stop();
              this.suberror.push({ typ: 'Error', err: "Something went wrong" });

            }
          );


        }
      },
      (error) => {
        // this.ngxService.stop();

        this.suberror = [];
        if (error.status == 0) {
          this.suberror.push({ typ: 'Error', err: "Unknown Error" });
        } else if (error.status == 400 || error.status == 401) {
          for (const property in error.error) {
            this.suberror.push({
              typ: property,
              err: error.error[property],
            });
          }
        } else {
          this.suberror.push({ typ: 'Error', err: "Internal Server Error" });
        }
      }
    );
  }
  checkuser() {
    if(this._authService.isAuth() == true){
      // this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
      this._router.navigate(["app/home/scan/0"]);
    }else{
      this._authService.logout();
    }
  }
  togglepassword(input: any){
    input.type = input.type === 'password' ? 'text' : 'password'; 
    if(input.type == 'text'){
      this.open = false;
    }
    else{
      this.open = true;
    }
  }
}
