import { Component, OnInit } from "@angular/core";
import { HelperService } from "../../storage-helper";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit {
  model: any = {};
  returnUrl = "pages/layout/list";
  suberror = [];

  constructor(
    private _helper: HelperService,
  ) {}

  ngOnInit(): void {
  this._helper.removeFromLocal('currentUserCS');
  this._helper.removeFromLocal('isLoggedIn');    
    // this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
    // this._router.navigate([this.returnUrl]);
  }

 
}
