import { Injectable } from "@angular/core";
import {  HttpClient,  HttpHeaders } from "@angular/common/http";

import { BehaviorSubject, throwError  } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { HelperService } from "../../storage-helper";
import { environment } from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
public config = environment;
private userDetails = new BehaviorSubject({});
Detail = this.userDetails.asObservable();
Udetails: {} = {};
public enableTour;
    constructor(private http: HttpClient, private _helper: HelperService) {}
    login(email: string, password: string) {

        let url = this.config.API_URL + 'auth/token/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(url, { email: email, password: password }, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    forgotPassword(param) {

        let url = this.config.API_URL + 'api/password_reset/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(url, param, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    resetPassword(param) {

        let url = this.config.API_URL + 'api/password_reset/confirm/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(url, param, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    changePassword(param) {
        
        let url = this.config.API_URL + 'api/change-password/';
        let user = this._helper.getFromLocal('currentUserCS');
        // let headers = new Headers({ 'Content-Type': 'application/json' });
      
            if (!!user) {
                let temp = JSON.parse(user)
                let token = temp;
                const headers = new HttpHeaders(
                    { 
                        'Content-Type': 'application/json',
                        'Authorization': 'jwt ' + token,
                          });
                const options = { headers: headers };
                
            
                return this.http.put(url, param, options)
                .pipe(
                    map((res: Response) => { 
                        return res;
                     })
                ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                    }
    }

    usersList() {

        let url = this.config.API_URL + 'api/users/';
        let user = this._helper.getFromLocal('currentUserCS');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'jwt ' + token,
                      });
            const options = { headers: headers };
            
        
        return this.http.get(url, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                }
    }

    usersListPerClient(clientId) {

        let url = this.config.API_URL + 'api/user-client/?client='+clientId;
        let user = this._helper.getFromLocal('currentUserCS');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'jwt ' + token,
                      });
            const options = { headers: headers };
            
        
        return this.http.get(url, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                }
    }


    activeAndDeactiveClient(params) {

        let url = this.config.API_URL + 'api/user-client/';
        let user = this._helper.getFromLocal('currentUserCS');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'jwt ' + token,
                      });
            const options = { headers: headers };
            
        
        return this.http.post(url, params, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                }
    }

    registration(params) {
        let url = this.config.API_URL + 'api/users/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(url, params, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    NewUserDetails(params) {
        let url = this.config.API_URL + 'api/create-new-user/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(url, params, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    editUser(params, id) {
        let url = this.config.API_URL + 'api/users/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        let user = this._helper.getFromLocal('currentUserCS');

        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'jwt ' + token,
                      });
            const options = { headers: headers };
            
        
            return this.http.put(url + id + "/", params, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                }

    }

    editProfile(params) {
        let url = this.config.API_URL + 'api/update-basic-details/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        let user = this._helper.getFromLocal('currentUserCS');

        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'jwt ' + token,
                      });
            const options = { headers: headers };
            
        
            return this.http.post(url, params, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                }

    }

    getNewUser(token) {

        let url = this.config.API_URL + 'api/get-newuser-token/?token=' + token;
        
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json'
                      });
            const options = { headers: headers };
            
        
        return this.http.get(url, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    emailActivation(token) {

        let url = this.config.API_URL + 'api/activate-link/?token=' + token;
        
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json'
                      });
            const options = { headers: headers };
            
        
        return this.http.get(url, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    me() {

        let url = this.config.API_URL + 'api/auth-user/';
        let user = this._helper.getFromLocal('currentUserCS');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    'Content-Type': 'application/json',
                    'Authorization': 'jwt ' + token,
                      });
            const options = { headers: headers };
            
        
        return this.http.get(url, options)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
                }
    }

    errorHandler(error: any): void {
        return error;
    }

    isAuth() {
        const user = this._helper.getFromLocal('currentUserCS');
        return !!user;
    }
    logout() {

        this._helper.removeFromLocal('currentUserCS');
        this._helper.removeFromLocal("isLoggedIn");

        
        // this.layoutService.rsToggleStatus.next(false);

    }

    nextMessage(message: object) {
        this.userDetails.next(message)
      }
}