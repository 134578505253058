import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span>
      <b> © {{ year }}  <a href="#">MD AIDER. </a> </b> 
    </span>
    <span>
      <img src="assets/md.png">
    </span>
  `,
})
export class FooterComponent {
  year: number;
  constructor() {
    this.year = new Date().getFullYear();
   }


}
