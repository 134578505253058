import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthComponent } from "./auth.component";
import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./login/login.component";


// import { AuthenticationService } from './_service/auth.service';
import { AuthGuard } from "./_guards";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { LogoutComponent } from "./logout/logout.component";
// import { SignupComponent } from "./signup/signup.component";
import { ForgotComponent } from "./forgot/forgot.component";
// import { UserIdentificationComponent } from "./user-identification/user-identification.component";
// import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// import { SubscriptionComponent } from "./subscription/subscription.component";
// import { PasswordResetComponent } from "./password-reset/password-reset.component";
// import { AdduserdetailsComponent } from "./adduserdetails/adduserdetails.component";
// import { ActivationEmailComponent } from "./activation-email/activation-email.component";
// import { InfiniteTypeDeleteModule } from "ngx-sbz-type-delete";
// import { StrManipulationPipe } from "src/app/shared/custom-pipes/str-manipulation.pipe";
// import { EqualValidatorDirective } from "src/app/shared/directives/equal-validator.directive";

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    // SignupComponent,
    ForgotComponent,
    // UserIdentificationComponent,
    // SubscriptionComponent,
    // PasswordResetComponent,
    // AdduserdetailsComponent,
    // ActivationEmailComponent,
    // StrManipulationPipe,
    // EqualValidatorDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    // BsDropdownModule.forRoot(),
    // InfiniteTypeDeleteModule
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  // exports: [StrManipulationPipe]
  // entryComponents: [AlertComponent],
})
export class AuthModule {}
