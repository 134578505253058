import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ActivationEmailComponent } from './activation-email/activation-email.component';
// import { AdduserdetailsComponent } from './adduserdetails/adduserdetails.component';
import { AuthComponent } from './auth.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
// import { PasswordResetComponent } from './password-reset/password-reset.component';
// import { SignupComponent } from './signup/signup.component';
// import { SubscriptionComponent } from './subscription/subscription.component';
// import { UserIdentificationComponent } from './user-identification/user-identification.component';
// import { AuthGuard } from './_guards';

const routes: Routes = [
    { path: '',
    component: AuthComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginComponent },
      { path: "logout", component: LogoutComponent },
      { path: "forgot", component: ForgotComponent },
      // { path: "password_reset", component: PasswordResetComponent },
      // { path: "user-identification", canActivate: [AuthGuard], component: UserIdentificationComponent },
      // { path: "subscription", canActivate: [AuthGuard], component: SubscriptionComponent },
      // { path: "registration", component: AdduserdetailsComponent },
      // { path: "activation-email", component: ActivationEmailComponent }
    ], },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {
}