<div class="container row mt-4">
  <div class="col-lg-6 col-md-6 col-sm-6 card">
    <img src="assets/logo.png" style="width: 80%; margin:2em">
  </div>
  <div class="col-lg-6 col-md-6 col-sm-6 card">
    <div class="card-header">
      <h5 class="card-title text-center text-center">Logout</h5>
    </div>
    <div class="card-body mt-4">
      <h6 class="card-subtitle mt-4  text-center">
        You have successfully logged out of MDAIDer.
      </h6>
      <div class="mt-4">
        <h6 class="text-center mt-4">
          <a routerLink="/auth/login" class="card-link text-center">Login Again</a>
        </h6>
      </div>
    </div>
  </div>
</div>