import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { HelperService } from "../../storage-helper";


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private _helper: HelperService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!!this._helper.getFromLocal('currentUserCS') == true) {
            return true;
        } 
            this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        
    }
   
}