<div class="container row mt-4">
  <div class="col-lg-6 col-md-6 col-sm-6 card">
    <img src="assets/logo.png" style="width: 80%; margin:2em">
  </div>
  <div class="col-lg-6 col-md-6 col-sm-6 card">
    <div class="card-header">
      <h5 class="card-title text-center text-center">Forgot Password</h5>
    </div>
    <div class="card-body mt-4">
      <!-- <form class=" mt-2" (ngSubmit)="f.form.valid && signin()" #f="ngForm"> -->
        <div class="form-label-group mb-3">
          <label for="inputemail">Email Address*</label>
          <input type="email" id="inputemail" class="form-control" placeholder="Email Address*" name="email" required/>
        </div>        
        <button class="btn btn-lg btn-primary px-5" type="submit">
          Submit
        </button>
      <!-- </form> -->
      <div class="mt-4">
        <h6 class="text-left mt-4">
          <a routerLink="/auth/login" class="card-link text-center">Sign In To Login</a>
        </h6>
      </div>
    </div>
  </div>
</div>