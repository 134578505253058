import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HelperService } from './storage-helper';


@Injectable()
export class AppService {
    constructor(private http: HttpClient, private _helper: HelperService) { }
    public _config = environment;

    public url = this._config.API_URL;

    public get(_apiUrl): Observable<any> {
        return this.http.get(`${this.url}` + _apiUrl,  this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError))
    }

    public post(_apiUrl, item: any): Observable<any> {
        return this.http.post(`${this.url}` + _apiUrl, item, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public postfile(_apiUrl, item: any): Observable<any> {
      return this.http.post(`${this.url}` + _apiUrl, item, this._headerfile()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    

    public patch(_apiUrl: any, item: any): Observable<any> {
        return this.http.patch(`${this.url}` + _apiUrl, item, this._header() ).pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
      }

    public put(_apiUrl, itemId: number, item: any): Observable<any> {
        return this.http.put(`${this.url}` + _apiUrl + itemId + '/', item, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public putWOID(_apiUrl, item: any): Observable<any> {
        return this.http.put(`${this.url}` + _apiUrl , item, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

   
    public delete(_apiUrl, item: any): Observable<any> {
        return this.http.delete(`${this.url}` + _apiUrl + item + '/', this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public FileextractData(res: any) {
        let body = res;
        return body;
    }
    public handleError_(error: Response | any) {
        return throwError(error);
    }


    _header() {
        let user = this._helper.getFromLocal('currentUserCS');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders(
                { 
                    
                    'Content-Type': 'application/json; charset=utf-8',
                     'Authorization': 'jwt ' + token,
                     });
            const options = { headers: headers };
            return options;
        }
    }

    _headerfile() {
        let user = this._helper.getFromLocal('currentUserCS');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp;
            const headers = new HttpHeaders({ 
                    'Authorization': 'jwt ' + token,
                });
            const options = { headers: headers };
            return options;
        }
    }


    public extractData(res: Response) {
        let body = res;
        return body;
    }
    public handleError(error: Response | any) {
        return throwError(error);
    }



}


