import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Router } from '@angular/router';


@Injectable()
export class ApiService { 
    constructor(private service: AppService, private _router: Router) { }

      getClients(id:any){
        return this.service.get('api/customer-clients/?client='+id);
      }

      getPatient(){
        return this.service.get(`api/patient-list`);
      }

      getPatientByDoctor(id:any){
        return this.service.get('api/patient-list-by-doctor/'+id); 
      }

      createVisit(data: any){
        return this.service.post('api/create-visit/', data);
      }

      imageVisit(data: any){
        return this.service.post('api/visit-image/', data);
      }
      

      updateVisit(data: any){
        return this.service.post('api/update-visit/', data);
      }

      prescriptionVisit(data: any){
        return this.service.post('api/prescription-visit/', data);
      }

      postscan(data: any){
        return this.service.post('api/patient-scan/', data);
      }

      search_pat(data: any){
        return this.service.get('api/patient-search/?title='+data);
      }
      

      getPatientId(id:any){
        return this.service.get('api/patient-list/'+id);
      }
      
      postBill(data: any){
        return this.service.post('api/packet-billing/', data);
      }
      
      getCPA(){
        return this.service.get('api/client-list/');
      }

      getCPAClient(id: any){
        return this.service.post('api/cpa-client/', id);
      }

      getAuth(){
        return this.service.get('api/auth-user/');
      }
      
      postUsers(data: any){
        return this.service.post('api/users/', data);
      }
      
      postPatient(data: any){
        return this.service.post('api/patient-create/', data);
      }

      getFilingQ(){
        return this.service.get('questionnaire/filing-qa-view/');
      }
      
      getReturnQ(){
        return this.service.get('questionnaire/return-qa-view/');
      }

      changepassword(params: any){
        return this.service.putWOID('api/change-password/', params);
      }

      getClientPacketView(id: number){
        return this.service.get('api/patient-list/?patient='+id);
      }


      getClientProfileView(id: number){
        return this.service.get('answer/client-profile-view/?client='+id);
      }

      postFileFiling(params: any){
        return this.service.postfile('answer/filing-answer/', params);
      }

      postFileReturn(params: any){
        return this.service.postfile('answer/return-answer/', params);
      }

      postFileQb(params: any){
        return this.service.postfile('answer/qb-answer/', params);
      }

      getPinnedandRecent(id: number){
        return this.service.get('boards/pinned/'+id);
      } 

      getBoardsQuestions(id: number){
        return this.service.get('boards/list/'+id+ '/');
      }

      FeedbackPost(params: any){
        return this.service.post('feedback/item/', params);
      }

      likeOrDislike(params: any){
        return this.service.post('api/post-answer/', params);
      }

      doDiscussion(params: any){
        return this.service.post('collaboration/discussion/', params);
      }

      questionStatus(params: any){
        return this.service.post('collaboration/update-status/', params);
      }

      shareBoard(params: any){
        return this.service.post('boards/group/', params);
      }

      getlikeOrDislike(){
        return this.service.get('api/post-answer/');
      }

      getPlans() {
      
        return this.service.get('subscription/plan/');
      }

      getConsumption(id) {
        return this.service.get('subscription/subscriptions/'+id + '/');
      }

      getPlanConsumption(id) {
        return this.service.get('subscription/plan/'+id+ '/');
      }

      subscribePlan(params) {
        return this.service.post('subscription/subscriptions/', params);
      }

      logout(){
          this._router.navigate(['/auth/logout'])
      }   
}


