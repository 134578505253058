<div class="container mt-4">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 card">
      <img src="assets/logo.png" style="width: 80%; margin:2em">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 card">
      <div class="card-header">
        <h5 class="card-title text-center text-center">Sign In To Continue</h5>
      </div>
      <div class="card-body">
        <!-- <h6 class="card-subtitle mt-2 text-muted text-center">Please login to continue</h6> -->
        <form class=" mt-2" (ngSubmit)="f.form.valid && signin()" #f="ngForm">
          <div class="form-label-group mb-3">
            <label for="inputemail">Email Address*</label>
            <input type="email" id="inputemail" class="form-control" placeholder="Email Address*" name="email" required
              autofocus [(ngModel)]="model.email" #email="ngModel" email />
          </div>
          <div class=" form-label-group">
            <label for="inputPassword">Password*</label>
            <div class="input-group">
            <input #input type="password" id="inputPassword" class="form-control" placeholder="Password*" name="password"
              autofocus [(ngModel)]="model.password" #pass="ngModel" required />
              <span class="input-group-text anchor" (click)="togglepassword(input)">
                <i class="fa fa-eye" *ngIf="open == true"></i> 
                <i class="fa fa-eye-slash" *ngIf="open == false"></i> 
              </span>
            </div>
            </div>

          <div *ngIf="suberror.length">
            <div *ngFor="let e of suberror" class="alert alert-warning mt-3 mb-0">
              {{ e.err }}
            </div>
          </div>
          <div class="py-4 d-flex justify-content-between">
            <a routerLink="/auth/forgot"> Forgot Password ? </a>
          </div>
          <button class="btn btn-lg btn-primary px-5" type="submit" [disabled]="!f.valid">
            Sign in
          </button>
        </form>

      </div>
    </div>
  </div>
</div>