<div class="container">

  <div class="row">
    <div class="col-md-2 col-lg-2">
    </div>
    <div class="col-md-8 col-lg-8 ml-1 mr-1" style="margin-top:4em">
      <router-outlet></router-outlet>
      <div class="p-2">
        <h6 class=" mt-2  text-center">
      © {{ year }}  <a href="#">MD AIDER. </a> 
      </h6></div>

    </div>
    <div class="col-md-2 col-lg-2">
    </div>

  </div>
</div>